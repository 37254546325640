import { Box } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";

import ContainerNarrow from "../components/layout/ContainerNarrow/container-narrow";
import Layout from "../components/layout/Main/main";
import SEO from "../components/seo";
import Headline from "../components/typography/Headline/headline";
import Paragraph from "../components/typography/Paragraph/paragraph";

const About = ({ data }) => (
    <Layout>
        <SEO title="Contact" description="Who is Virginia Dae?" />
        <ContainerNarrow>
            <Box my={5}>
                <Headline textAlign="center">About Virgina Dae</Headline>
            </Box>
            <Box row justifyContent="center" mb={5}>
                <Box col={{ xs: 1, sm: 2 / 3 }}>
                    <GatsbyImage
                        fluid={data.virginiaPicture.childImageSharp.fluid}
                    />
                </Box>
            </Box>
            <Box mb={5} textAlign="justify">
                <Paragraph>
                    With parents teaching{" "}
                    <strong>history and literature</strong> it seemed “natural”
                    to me to study both subjects. A degree from{" "}
                    <strong>Sorbonne University</strong> was a kind of must to
                    “validate” my attachment to their values. Speaking{" "}
                    <strong>three languages</strong>, I worked as an{" "}
                    <strong>interpreter</strong>, as well as in the{" "}
                    <strong>airline business</strong>, in{" "}
                    <strong>travel assistance</strong>, and in{" "}
                    <strong>journalism</strong> on occasions.
                </Paragraph>
                <Paragraph>
                    A broken leg took me back “home”, back to the{" "}
                    <strong>magical worlds of history and literature</strong>.
                    The wall full of books in my apartment turned into a source
                    of nourishment. My favorite “activity” became reading and
                    imagining myself walking the streets of Paris and{" "}
                    <strong>passing from one epoch to another</strong>.
                </Paragraph>
                <Paragraph>
                    From the windows of my apartment (a kind of bird’s nest
                    looking over the rooftops of Paris to the Eiffel Tower) I
                    could almost see myself in the narrow streets and hear the
                    sound of my steps on the old Parisian pavements. Members of
                    French royalty seemed to join me by whispering the stories
                    of their lives. Accounts of France’s Revolutions
                    (1789,1830,1848,1870) prevented me from sleeping. Writers,
                    musicians, painters - they guided me to the places which
                    inspired them. Their Parisian heritage is also mine.
                </Paragraph>
                <Paragraph>
                    What is now <strong>dear to my heart</strong> is sharing all
                    this with you -{" "}
                    <strong>visitors, dreamers, lovers of Paris</strong>.
                </Paragraph>
                <Paragraph>
                    When you look up the word <strong>“INSIGHT”</strong> in the
                    dictionary, you’ll see it defined as: “the capacity to gain
                    an accurate and deep understanding of someone or something”.
                    Synonyms include:{" "}
                    <strong>perception, discernment and REVELATION</strong>.
                </Paragraph>
                <Paragraph>
                    This is precisely what the{" "}
                    <strong>PARIS INSIGHT WALKS</strong> offer you when you
                    choose <strong>Virginia Dae</strong> as your{" "}
                    <strong>guide for Paris</strong>.
                </Paragraph>
            </Box>
        </ContainerNarrow>
    </Layout>
);

About.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query AboutPageQuery {
        virginiaPicture: file(
            relativePath: { eq: "virginia.jpg" }
            sourceInstanceName: { eq: "images" }
        ) {
            childImageSharp {
                fluid(maxWidth: 562) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default About;
