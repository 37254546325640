import styled from "@xstyled/styled-components";
import { space } from "@xstyled/system";

import { CONTAINER_NARROW } from "../../../style/layout";

const ContainerNarrow = styled.div`
    ${CONTAINER_NARROW};
    ${space};
    z-index: 1;
`;

export default ContainerNarrow;
