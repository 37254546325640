import styled from "@xstyled/styled-components";
import { space, textAlign } from "@xstyled/system";

import { inlineStyle } from "../../../style/typography";

const Paragraph = styled.p`
    ${inlineStyle};
    ${space};
    ${textAlign};
`;

export default Paragraph;
